import * as Sentry from '@sentry/vue';
import type AnalyticsModule from './AnalyticsModule';

export default class implements AnalyticsModule {
  constructor() {
    if (import.meta.env.MODE === 'prod') {
      // https://docs.sentry.io/platforms/javascript/guides/vue/features/vue-router/
      Sentry.browserTracingIntegration({
        routeLabel: 'path',
      });
    }
  }

  trackEvent(_eventName: string, _properties: Record<string, any>) {
    // NOOP
    // Only using Sentry for error tracking right now
  }

  logError(error: any, { route }: Record<string, any>) {
    // https://docs.sentry.io/platforms/javascript/guides/vue/usage/
    // Sends error to Sentry with optional tags and extras
    Sentry.captureException(error, {
      extra: { route },
    });
  }

  // https://docs.sentry.io/platforms/javascript/guides/vue/enriching-events/identify-user/
  setUserProperties({ user, org }) {
    if (!user || !org) return;
    Sentry.setUser({
      // User
      id: user.id,
      username: `${user.firstName} ${user.lastName}`,
      email: user.email,

      'Cognito ID': user.cognitoID,
      'Created At': user.createdAt,
      'Activated At': user.activatedAt,
      Status: user.status,
      Role: user.role,
      Team: user.team,

      // Organisation
      'Organisation Name': org.name,
      'Organisation ID': org.id,
      'Org Status': org.status,
      Paid: org.paid,
      Location: org.location,
      Supplier: org.isSupplier,
      Client: org.isClient,
      'Scoping Questions Complete': org.scopingComplete,
      'Initial Assessment Complete': org.initialAssessmentComplete,
      'Initial Assessment Completed At': org.initialAssessmentCompletedAt,
    });
  }
}
